<template>
  <div>
    <AppointmentFilter
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
      @verifyFilter="verifyFilter"
    />
    <b-card class="table-expand">
      <TitleTable titleTable="Revenue Cycle Management" iconTable="EyeIcon" />
      <div class="m-1">
        <changePosting
          v-if="seeModalBoolean"
          :itemEdit="itemEdit"
          @validationCheck="NotificationCheck"
          @sendData="changePostingSuccesfully"
          @hideModal="hideChangePosting"
        />
        <AppointmentPay
          v-if="conditionPayAppointment"
          :itemAppointment="itemToPay"
          @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked"
          @NotificationCheck="NotificationCheck"
          @updateItemAppoiment="handleUpdateAppoiment"
        />
        <AppointmentCancel
          v-if="conditionCancelAppointment"
          :itemAppointment="itemToPay"
          @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked"
          @NotificationCheck="NotificationCheck"
        />
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-between flex-wrap mb-1 mb-md-0"
          >
            <div>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t("Entries") }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <!-- <b-button
                variant="primary"
                @click="isAddNewAppointmentSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Appointment</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
        <b-row align-h="end" class="total-row">
          <b-col cols="auto">
            <b-button
              variant="primary"
              @click="selectRows.length ? clearSelected() : selectAllRows()"
            >
              {{ selectRows.length ? "Clear Selected" : "Select Page" }}
            </b-button>
          </b-col>
          <b-col cols="auto" class="total-col selected">
            Selected Posting Total: {{ formatMoney(selectedTotal()) }}
          </b-col>
          <b-col cols="auto" class="total-col">
            Total Posting: {{ formatMoney(totalPosting) }}
          </b-col>
        </b-row>
      </div>

      <LoadingTable v-if="items === null" :columns="fields.length" />
      <b-table
        v-else
        ref="selectableTable"
        :items="items"
        :fields="fields"
        select-mode="range"
        @row-selected="onRowSelected"
        selectable
        striped
        bordered
        hover
        class="position-relative text-center"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        small
        :sticky-header="items.length > 5 ? true : false"
        selected-variant="success"
      >
        <template #cell(select)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(show)="row">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            />
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row style="text-align: start" class="mb-2">
              <b-col md="4" class="mb-3">
                <strong>Phone : </strong
                >{{ formatPhone(row.item.patients.phone) }}
              </b-col>
              <b-col md="4">
                <strong>Visit Date : </strong>{{ row.item.visit_date }}
              </b-col>
              <b-col md="4">
                <strong>{{ $t("Visit Reason") }} : </strong
                ><span>{{
                  row.item.type_consultations
                    ? row.item.type_consultations.name
                    : "Not Found"
                }}</span>
              </b-col>
              <b-col md="4">
                <strong>Visit Type : </strong>
                <span v-if="row.item.type_visits">
                  <span
                    :style="`text-decoration: underline ${row.item.type_visits.color}`"
                    >{{ row.item.type_visits.name }}</span
                  >
                </span>
                <span v-else>Not Found</span>
              </b-col>
              <b-col md="4" class="d-flex" v-if="row.item.frontdesks">
                <strong> FrontDesk: </strong>
                <b-link
                  :to="{
                    name: 'web-settings-users',
                    params: { id: row.item.frontdesks.id },
                  }"
                  class="text-left pr-1"
                >
                  <p class="font-weight-bold d-flex m-0 pl-1">
                    {{ row.item.frontdesks.full_name }}
                  </p>
                </b-link>
                <b-avatar
                  size="32"
                  v-if="row.item.frontdesks.avatar"
                  :src="row.item.frontdesks.avatar"
                />
              </b-col>
              <b-col
                md="4"
                class="d-flex"
                v-if="row.item.office_visit && row.item.office_visit.omas"
              >
                <strong> Oma: </strong>
                <b-link
                  :to="{
                    name: 'web-settings-users',
                    params: { id: row.item.office_visit.omas.id },
                  }"
                  class="text-left pr-1"
                >
                  <p class="font-weight-bold d-flex m-0 pl-1">
                    {{ row.item.office_visit.omas.full_name }}
                  </p>
                </b-link>
                <b-avatar
                  size="32"
                  v-if="row.item.office_visit.omas.avatar"
                  :src="row.item.office_visit.omas.avatar"
                />
              </b-col>
            </b-row>
            <div>
              <div
                class="little-cards"
                v-for="({ note }, index) in row.item.appointment_note
                  .slice()
                  .reverse()"
                :key="index"
              >
                {{ note }}
              </div>
            </div>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(patients)="data">
          <div class="d-flex">
            <b-link
              :to="{
                name: 'patients-view',
                params: { id: data.item.patients.id },
              }"
              class="text-left"
            >
              <p class="font-weight-bold d-flex m-0 pl-1">
                {{ data.item.patients.name }} {{ data.item.patients.lastname }}
              </p>
            </b-link>
          </div>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(facilities)="data">
          <span>{{ data.item.facilities.name }}</span>
        </template>

        <template #cell(claim_status)="data">
          <div
            @click="
              assignVitalInfo({
                id: data.item.id,
                tempInfo: data.item.insurance.claim_insurance_status,
                method: 'claim_status',
              })
            "
          >
            <b-dropdown
              offset="0"
              no-caret
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
            >
              <template #button-content class="text-nowrap">
                <b-badge
                  v-if="data.item.insurance.claim_insurance_status"
                  :style="{
                    'background-color':
                      data.item.insurance.claim_insurance_status.color,
                    'font-size': '0.69vw',
                  }"
                  v-b-tooltip.hover.top="
                    data.item.insurance.claim_insurance_status.name
                  "
                  class="badge-glow cursor-pointer"
                  @click="
                    assignVitalInfo({
                      id: data.item.id,
                      tempInfo: data.item.insurance.claim_insurance_status,
                      method: 'claim_status',
                    })
                  "
                >
                  <span class="align-text-top text-capitalize">{{
                    getInitials(data.item.insurance.claim_insurance_status.name)
                  }}</span>
                </b-badge>
              </template>
              <b-dropdown-form>
                <v-select
                  v-model="newClaimStatus"
                  label="name"
                  class="select-claim"
                  @search="filterClaimStatus"
                  :options="claimOptions"
                  @input="changeStatus(data.item)"
                />
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(insurance_name)="data">
          <div
            @click="
              assignVitalInfo({
                id: data.item.id,
                tempInfo: data.item.insurance.insurance_type,
                method: 'insurance_name',
              })
            "
          >
            <b-dropdown
              offset="0"
              no-caret
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
            >
              <template #button-content class="text-nowrap">
                <b-badge
                  v-if="data.item.insurance.insurance_type"
                  variant="primary"
                  v-b-tooltip.hover.top="
                    data.item.insurance.insurance_type.name
                  "
                  class="badge-glow cursor-pointer"
                  @click="
                    assignVitalInfo({
                      id: data.item.id,
                      tempInfo: data.item.insurance.insurance_type,
                      method: 'insurance_name',
                    })
                  "
                >
                  <span
                    class="align-text-top text-capitalize"
                    v-if="data.item.insurance.insurance_type"
                  >
                    {{ data.item.insurance.insurance_type.name }}
                  </span>
                </b-badge>
              </template>
              <b-dropdown-form>
                <v-select
                  v-model="newInsuranceName"
                  label="name"
                  class="select-claim"
                  @search="filterInsurance"
                  :options="insuranceOptions"
                  @input="changeInsurance(data.item)"
                />
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(rcm_transfer_date)="data">
          <div @click="appointmentDateId = data.item.id">
            <b-dropdown
              offset="0"
              no-caret
              :ref="`dd-${data.item.id}`"
              variant="link"
              size="sm"
            >
              <template #button-content>
                <span>{{ data.item.insurance.rcm_transfer_date }}</span>
              </template>
              <b-dropdown-form>
                <b-button
                  v-if="!editingDate"
                  variant="warning"
                  @click.stop="
                    editingDate = true;
                    appointmentDateId = data.item.id;
                  "
                  >Change</b-button
                >
                <b-button
                  v-if="!editingDate"
                  @click="
                    appointmentDateId = data.item.id;
                    deleteDate();
                  "
                  class="mt-1"
                  variant="danger"
                  >Delete</b-button
                >
                <flat-pickr
                  v-else
                  v-model="rcmDate"
                  :config="dropdownConfig"
                  @on-close="editingDate = !editingDate"
                  class="form-control"
                  style="max-width: 6.5vw"
                  placeholder="MM-DD-YYYY"
                />
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(rcm_transfer_id)="data">
          <div
            @click="
              assignVitalInfo({
                id: data.item.id,
                tempInfo: data.item.insurance.rcm_transfer_id,
                method: 'transfer_id',
              })
            "
          >
            <b-dropdown offset="0" variant="link" size="sm" no-caret>
              <template #button-content>
                <span
                  @click="
                    assignVitalInfo({
                      id: data.item.id,
                      tempInfo: data.item.insurance.rcm_transfer_id,
                      method: 'transfer_id',
                    })
                  "
                >
                  {{ data.item.insurance.rcm_transfer_id }}
                </span>
              </template>
              <b-dropdown-form>
                <b-form-input v-model="totalInput" type="text" />
                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="changeTransferId(data.item)"
                  >Change</b-button
                >
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(posting)="data">
          <div
            @click="
              assignVitalInfo({
                id: data.item.id,
                tempInfo: data.item.insurance.posting,
                method: 'posting',
              })
            "
          >
            <b-dropdown
              variant="link"
              offset="0"
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
            >
              <template #button-content class="text-nowrap">
                <span
                  class="align-text-top text-capitalize posting"
                  @click="
                    assignVitalInfo({
                      id: data.item.id,
                      tempInfo: data.item.insurance.posting,
                      method: 'posting',
                    })
                  "
                >
                  {{ formatMoney(data.item.insurance.posting) }}
                </span>
              </template>
              <b-dropdown-form>
                <b-form-input v-model="posting" type="number" />
                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="changeValuePosting(data.item.id)"
                  >Change</b-button
                >
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(claim_id)="data">
          <div
            @click="
              assignVitalInfo({
                id: data.item.id,
                tempInfo: data.item.insurance.claim_id,
                method: 'claim_id',
              })
            "
          >
            <b-dropdown
              variant="link"
              offset="0"
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
            >
              <template #button-content class="text-nowrap">
                <span
                  class="align-text-top text-capitalize posting"
                  @click="
                    assignVitalInfo({
                      id: data.item.id,
                      tempInfo: data.item.insurance.claim_id,
                      method: 'claim_id',
                    })
                  "
                >
                  {{ data.item.insurance.claim_id }}
                </span>
              </template>
              <b-dropdown-form>
                <b-form-input v-model="claim_id" />
                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="changeValueClaim(data.item.id)"
                  >Change</b-button
                >
              </b-dropdown-form>
            </b-dropdown>
          </div>
        </template>
        <template #cell(copay)="data">
          <span v-if="data.item.copay">
            {{ formatMoney(data.item.copay) }}
          </span>
          <span v-else>{{ formatMoney(0) }}</span>
        </template>
        <template #cell(co_insurance)="data">
          <span v-if="data.item.coinsurance">
            {{ formatMoney(data.item.coinsurance) }}
          </span>
          <span v-else>{{ formatMoney(0) }}</span>
        </template>
        <template #cell(deductible)="data">
          <span v-if="data.item.deductible">
            {{ formatMoney(+data.item.deductible) }}
          </span>
          <span v-else>{{ formatMoney(0) }}</span>
        </template>
        <template #cell(pending_payment_amount)="data">
          <b
            @click="
              seeModalBoolean = true;
              itemEdit = data.item;
            "
            class="payment-amount"
          >
            {{ formatMoney(data.item.total_pending_payment) }}
          </b>
        </template>
        <template #cell(total)="data">
          {{ formatMoney(data.item.total) }}
        </template>
        <template #cell(cash)="data">
          {{ formatMoney(data.item.cash) }}
        </template>
        <template #cell(cc)="data">
          {{ formatMoney(data.item.cc) }}
        </template>
        <template #cell(pay_status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="resolveUserPayStatusVariant(data.item.pay_status.name)"
              class="badge-glow cursor-pointer"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.pay_status.name
              }}</span>
            </b-badge>
          </div>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BCardGroup,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";

import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack";
import SpinnerLoading from "@/components/SpinnerLoading";
import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import LoadingTable from "@/components/LoadingTable.vue";
import flatPickr from "vue-flatpickr-component";

import changePosting from "@/views/admin/rcm/appointment-actions/changePosting.vue";
import AppointmentFilter from "@/views/admin/rcm/appointment-actions/AppointmentFilter.vue";
import AppointmentPay from "./AppointmentPay.vue";
import AppointmentCancel from "./AppointmentCancel";

import axiosCV from "@/services/admin/consults/claimStatus.js";
import axiosA from "@/services/rcm";
import { fromConfig } from "@/mixins/configFormatCal";
import { formatPhone } from "@/mixins/formatPhone";

export default {
  components: {
    flatPickr,
    BFormGroup,
    BFormInput,
    BCardGroup,
    BButton,
    BTable,
    BFormCheckbox,
    BCard,
    BRow,
    BFormTextarea,
    BCol,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    vSelect,
    BDropdownForm,
    GoBack,
    SpinnerLoading,
    ToastNotification,
    PaginationTable,
    TitleTable,
    LoadingTable,

    changePosting,
    AppointmentFilter,
    AppointmentPay,
    AppointmentCancel,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      formatPhone,
      rcmDate: "",
      posting: null,
      claim_id: null,
      fields: [
        {
          key: "select",
          class: "th-class-adjusted-appointment short default-size",
        },
        {
          key: "show",
          class: "th-class-adjusted-appointment short default-size",
        },
        {
          key: "facilities",
          label: "Facility",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "visit_date",
          label: "DOS",
          sortable: true,
          class: "th-class-adjusted-appointment lil-names default-size",
        },
        {
          key: "patients",
          label: "Patient Name",
          sortable: true,
          class: "th-class-adjusted-appointment names default-size",
        },
        {
          key: "patients.date_birth",
          label: "DOB",
          sortable: true,
          class: "th-class-adjusted-appointment lil-names default-size",
        },
        {
          key: "claim_status",
          label: "Claim Status",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "type_visits",
          label: "Visit Type",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "insurance_name",
          label: "Insurance Name",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "rcm_transfer_id",
          label: "Transfer ID",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "claim_id",
          label: "Claim No",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "rcm_transfer_date",
          label: "Transfer Date",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        {
          key: "copay",
          label: "Co-pay",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
          // class: "th-class-adjusted-appointment",
        },
        {
          key: "co_insurance",
          label: "Co-Insurance",
          class: "th-class-adjusted-appointment default-size",
          sortable: true,
          // class: "th-class-adjusted-appointment",
        },
        {
          key: "deductible",
          class: "th-class-adjusted-appointment default-size lil-names",
          label: "Deductible",
          sortable: true,
          // class: "th-class-adjusted-appointment",
        },
        {
          class: "th-class-adjusted-appointment default-size",
          key: "posting",
          label: "Posting",
          sortable: true,
          // class: "th-class-adjusted-appointment",
        },
        {
          class: "th-class-adjusted-appointment default-size",
          key: "pending_payment_amount",
          label: "Pending Payment",
          sortable: true,
          // class: "th-class-adjusted-appointment",
        },
        {
          key: "total",
          label: "Total Payment",
          sortable: true,
          class: "th-class-adjusted-appointment default-size",
        },
        // { key: "actions", class: "th-class-adjusted-appointment actions" },
      ],
      newClaimStatus: null,
      newInsuranceName: null,
      itemEdit: {},
      insuranceOptions: [],
      selectRows: [],
      claimOptions: [],
      checkCodeQrModal: false,
      userAppointmentId: 0,
      totalInput: "",
      items: null,
      perPageOptions: [10, 25, 50, 100],
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      perPage: 50,
      loading: false,
      isSortDirDesc: true,
      sortBy: "id",
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isAddNewAppointmentSidebarActive: false,
      seeModalBoolean: false,
      conditionPayAppointment: false,
      conditionCancelAppointment: false,
      itemToPay: null,
      filtro: false,
      editingDate: false,
      appointmentFilter: null,
      config: fromConfig({ dateFormat: "m-d-Y" }),
      dropdownConfig: fromConfig({ dateFormat: "m-d-Y" }),
      total: 0,
      appointmentDateId: 0,
      totalPosting: 0,
    };
  },
  created() {
    this.filterInsurance("");
    axiosCV.visitList(25).then(({ registro: { data } }) => {
      this.claimOptions = data;
    });
    this.getAppointments();
  },
  watch: {
    items() {
      this.total = 0;
      this.items.forEach((selected) => {
        this.total = this.total + +selected.total;
      });
      return this.total;
    },
    rcmDate() {
      if (this.rcmDate == "") return;
      const data = this.getDate();
      this.sendData({ data, message: "Dates changed successfuly" });
    },
    perPage(newVal) {
      if (!this.filtro) {
        this.getAppointments();
      } else {
        this.searchFilter(this.appointmentFilter);
      }
    },
  },
  methods: {
    verifyFilter() {
      this.$refs.toast.warning(
        "You need to select one option in claim insurance status!"
      );
    },
    getDate() {
      const [month, day, year] = this.rcmDate.split("-");
      const data = {
        appointments: this.selectRows.length
          ? this.selectRows.map((e) => e.id)
          : [this.appointmentDateId],
        rcm_transfer_date: `${year}-${month}-${day}`,
        rcm_transfer_id: null,
        claim_insurance_status_id: null,
      };
      this.editingDate = false;
      return data;
    },
    deleteDate() {
      const data = this.getDate();
      data.rcm_transfer_date = "";
      this.sendData({ data, message: "Dates changed successfuly" });
    },
    assignVitalInfo({ id, tempInfo, method }) {
      this.appointmentDateId = id;
      switch (method) {
        case "posting":
          this.posting = tempInfo;
          break;
        case "claim_status":
          this.newClaimStatus = tempInfo;
          break;
        case "insurance_name":
          this.newInsuranceName = tempInfo;
          break;
        case "transfer_id":
          this.totalInput = tempInfo;
        case "claim_id":
          this.claim_id = tempInfo;
          break;
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    getInitials(sentence) {
      // Dividir la oración en palabras
      const words = sentence.split(" ");

      // Obtener la primera letra de cada palabra y unirlas
      const initials = words.map((word) => word[0]).join("");

      return initials.toUpperCase();
    },
    hideChangePosting() {
      this.seeModalBoolean = false;
    },
    changeValuePosting(id) {
      axiosA
        .updatePosting({ posting: this.posting }, id)
        .then(({ status, message, data }) => {
          const type = status == "OK" ? "success" : status;
          this.NotificationCheck({ type, message });
          this.seeModalBoolean = false;
          this.pageChanged(this.currentPage.page);
        });
    },
    changeInsurance(item) {
      axiosA
        .updatePosting({ insurance_type_id: this.newInsuranceName.id }, item.id)
        .then(({ status, message, data }) => {
          const type = status == "OK" ? "success" : status;
          this.NotificationCheck({ type, message });
          this.seeModalBoolean = false;
          this.pageChanged(this.currentPage.page);
        });
    },
    changeValueClaim(id) {
      axiosA
        .updatePosting({ claim_id: this.claim_id }, id)
        .then(({ status, message, data }) => {
          const type = status == "OK" ? "success" : status;
          this.NotificationCheck({ type, message });
          this.seeModalBoolean = false;
          this.pageChanged(this.currentPage.page);
        });
    },
    changePostingSuccesfully(form) {
      axiosA.updatePendingPayment(form).then(({ status, message, data }) => {
        const type = status == "OK" ? "success" : status;
        this.NotificationCheck({ type, message });
        this.seeModalBoolean = false;
        this.pageChanged(this.currentPage.page);
      });
    },
    selectedTotal() {
      let total = 0;
      this.selectRows.forEach((selected) => {
        total = total + +selected.insurance.posting;
      });
      return total;
    },
    sendData({ data, message }) {
      axiosA.updateMultiple(data).then(() => {
        this.NotificationCheck({ type: "success", message });
        this.rcmDate = "";
        this.pageChanged(this.currentPage.page);
      });
    },
    changeStatus(appointment) {
      const newAppointments = this.selectRows.length
        ? this.selectRows.map((e) => e.id)
        : [appointment.id];
      const data = {
        appointments: newAppointments,
        rcm_transfer_date: appointment.rcm_transfer_date,
        rcm_transfer_id: appointment.rcm_transfer_id,
        claim_insurance_status_id: this.newClaimStatus.id,
      };
      this.sendData({ data, message: "Status changed succesfuly" });
    },
    changeTransferId(appointment) {
      const data = {
        appointments: this.selectRows.length
          ? this.selectRows.map((e) => e.id)
          : [appointment.id],
        rcm_transfer_date: appointment.rcm_transfer_date,
        claim_insurance_status_id: appointment.claim_insurance_status_id,
        rcm_transfer_id: this.totalInput,
      };
      this.sendData({ data, message: "Status changed succesfuly" });
      this.totalInput = "";
    },
    filterClaimStatus(value) {
      axiosCV
        .visitFilter(10, { name: value })
        .then(({ registro: { data } }) => {
          this.claimOptions = data;
        });
    },
    filterInsurance(value) {
      axiosA
        .searchTypeInsurance(25, { name: value })
        .then(({ registro: { data } }) => {
          this.insuranceOptions = data;
        });
    },
    onRowSelected(items) {
      this.selectRows = items;
    },
    formatMoney(number) {
      if (isNaN(number)) return number ? number : "$0.00";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(number);
    },
    scanPromotion(id) {
      if (this.checkCodeQrModal) {
        this.codeQr = null;
      }
      this.userAppointmentId = id;
      this.checkCodeQrModal = !this.checkCodeQrModal;
    },
    closeModalMembership(data) {
      this.codeQr = data;
    },
    getAppointments() {
      axiosA.appointmentList(this.perPage).then(
        ({
          registro: {
            appointments: { data, from, to, total, current_page },
            total_posting,
          },
        }) => {
          this.totalPosting = total_posting;
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = from;
          this.dataMeta.to = to;
          this.currentPage.page = current_page;
        }
      );
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
    resolveUserPayStatusVariant(status) {
      if (status === "Paid") return "success";
      if (status === "Unpaid") return "warning";
    },
    isCheckPay(item) {
      if (
        item.pay_status_id === 1 ||
        item.pay_status_id === 4 ||
        item.office_visit.visit_statuses_id === 7
      ) {
        return false;
      } else {
        return true;
      }
    },
    createAppointment({ type, message }) {
      this.NotificationCheck({ type, message });

      if (type === "danger") {
        this.isAddNewAppointmentSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    deleteAppointment(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosA.appointmentDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Appointment has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.appointmentFilter = value;
        axiosA
          .appointmentFilter(this.perPage, value)
          .then(
            ({
              registro: {
                appointments: { data, from, to, total, current_page },
                total_posting,
              },
            }) => {
              this.totalPosting = total_posting;
              this.loading = false;
              this.items = data;
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = from;
              this.dataMeta.to = to;
              this.currentPage.page = current_page;
            }
          )
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getAppointments();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosA.appointmentPagination(this.perPage, page).then(
          ({
            registro: {
              appointments: { data, from, to, total, current_page },
              total_posting,
            },
          }) => {
            this.totalPosting = total_posting;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = from;
            this.dataMeta.to = to;
            this.currentPage.page = current_page;
          }
        );
      } else {
        axiosA
          .appointmentFilterPagination(
            this.perPage,
            page,
            this.appointmentFilter
          )
          .then(
            ({
              registro: {
                appointments: { data, from, to, total, current_page },
                total_posting,
              },
            }) => {
              this.totalPosting = total_posting;
              this.items = data;
              this.totalUsers = total;
              this.dataMeta.of = total;
              this.dataMeta.from = from;
              this.dataMeta.to = to;
              this.currentPage.page = current_page;
            }
          );
      }
    },
    payAppointment(item) {
      this.itemToPay = item;
      this.conditionPayAppointment = true;
    },
    addMembership(item) {
      //console.log(item);
    },
    cancelAppointment(item) {
      this.itemToPay = item;
      this.conditionCancelAppointment = true;
    },
    closeModalPay() {
      this.conditionPayAppointment = false;
      this.conditionCancelAppointment = false;
      this.itemToPay = null;
      this.pageChanged(this.currentPage.page);
    },
    paymentChecked(value) {
      this.pageChanged(this.currentPage.page);
    },
    getTimesTooltip(item) {
      /* nombre - tiempo (2023-04-10T22:05:16.000000Z = 22:05:16 sin moment)
        nombre - tiempo  */
      let times = "";
      item.forEach((time) => {
        times += `${time.prefix} - ${time.time} <br>`;
      });
      return times;
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
    },
    handleUpdateAppoiment(value) {
      this.conditionPayAppointment = false;
      this.conditionCancelAppointment = false;
      this.itemToPay = null;

      setTimeout(() => {
        this.itemToPay = value;
        this.conditionPayAppointment = true;
      }, 200);
    },
  },
};
</script>
<style lang="scss">
b-dropwdown {
  padding: 0;
}

.th-class-adjusted-appointment.short {
  width: 2.8vw;
}

.th-class-adjusted-appointment.phone {
  width: 6vw;
}

.th-class-adjusted-appointment {
  padding: 0.5vw 0 !important;
}

.th-class-adjusted-appointment.actions {
  padding: 0.7rem 0 !important;
}

.th-class-adjusted-appointment.transfer_date {
  width: 6vw;
}

.th-class-adjusted-appointment.lil-names {
  width: 5vw;
}

.th-class-adjusted-appointment.names {
  width: 10vw;
}

.th-class-adjusted-appointment.default-size {
  font-size: 0.61vw;
}

.table-expand .card-header {
  padding: 0 1rem !important;
}

.b-table-sticky-header {
  max-height: 80vh !important;
  font-size: 0.65vw;
}

.total-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.total-col {
  margin-left: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f0f0f0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
}

.total-col.selected {
  background-color: #d1e7dd;
  color: #0f5132;
}

.little-cards {
  border-radius: 15px;
  padding: 2vh;
  margin: 1vh;
  border: 1px solid #5a83b2;
}

.changed-button {
  max-width: 1vw;
}

.select-claim {
  width: 12vw;
  z-index: 999;
}

.posting {
  font-size: 0.65vw;
  max-width: 1vw;
}

.payment-amount {
  font-size: 0.7vw;
  color: #7597be;
}
</style>

<template>
    <b-modal centered v-model="waos" hide-footer title="Pending Payment" @hide="onHide" @shown="onShown">
        <validation-observer #default="{ handleSubmit }" ref="payRef">
            <b-form @submit.prevent="handleSubmit(sendData)">
                <validation-provider #default="{ errors }" name="Total Pending" rules="required">
                    <b-form-group label="Total Pending" label-for="payment-amount">
                        <b-form-input v-model="form.total_pending" type="number"
                            :state="errors.length > 0 ? false : null" />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider #default="{ errors }" name="Note" rules="required">
                    <b-form-group label="Note" label-for="note">
                        <b-form-textarea v-model="form.note" :state="errors.length > 0 ? false : null" />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-row align-h="between" style="padding-top: 1vh">
                    <b-col>
                        <b-form-checkbox v-model="copay" value="copay"
                            :disabled="deductible != '' || coinsurance != ''">Co
                            Pay</b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox v-model="deductible" value="deductible"
                            :disabled="copay != '' || coinsurance != ''">Deductible</b-form-checkbox>
                    </b-col>
                    <b-col>
                        <b-form-checkbox v-model="coinsurance" value="coinsurance"
                            :disabled="copay != '' || deductible != ''">Co Insurance</b-form-checkbox>
                    </b-col>
                </b-row>
                <hr />
                <b-row align-h="end">
                    <b-col cols="auto">
                        <b-button type="submit" variant="primary">Update</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormInput,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea
} from 'bootstrap-vue';
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        BModal,
        BFormInput,
        BFormCheckbox,
        BButton,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        BFormTextarea
    },
    data() {
        return {
            required,
            waos: true,
            form: this.setForm(false),
            deductible: "",
            copay: "",
            coinsurance: ""
        }
    },
    watch: {
        deductible() {
            this.deductible ? this.changeNote("Deductible") : ""
        },
        copay() {
            this.copay ? this.changeNote("Co-pay") : ""
        },
        coinsurance() {
            this.coinsurance ? this.changeNote("Co-Insurance") : ""
        },
    },
    props: {
        itemEdit: {
            required: true,
            default: {},
            type: Object,
        },
    },
    methods: {
        changeNote(method) {
            this.form.note = `The amount of $${this.form.total_pending} was not charged during the patient's visit as a ${method}`
        },
        onShown() {
            this.setForm(this.itemEdit)
        },
        setForm(item) {
            if (item) {
                return this.form = {
                    total_pending: 0,
                    user_id: item.patients_id,
                    appointment_id: item.id,
                    type_insurance: "",
                    note: ""
                }
            }
            return {
                total_pending: 0,
                user_id: 0,
                appointment_id: 0,
                type_insurance: "",
                note: ""
            }
        },
        onHide() {
            this.$emit("hideModal")
        },
        getType() {
            return this.copay ? this.copay : (this.deductible ? this.deductible : this.coinsurance)
        },
        sendData() {
            this.form.type_insurance = this.getType()
            if (!this.form.type_insurance) return this.$emit("validationCheck", { type: 'danger', message: "You have to check one field of deductible or co pay" })
            this.$emit("sendData", this.form)
        }
    }
}
</script>

<style></style>
<template>
  <b-card no-body>
    <b-card-header class="pb-50 cursor-pointer" @click="visible = !visible">
      <div class="d-flex justify-content-between">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Patient Name</label>
            <b-form-input autofocus v-model.trim="filters.patient_name" @keyup.enter="searchFilter" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>DOB</label>
            <flat-pickr v-model="filters.dob" :config="config" class="form-control" placeholder="MM-DD-YYYY"
              type="date" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Claim Insurance Status</label>
            <v-select v-model="filters.claim_insurance_status_id" multiple class="w-100" :options="claimStatusOptions"
              @search="filterClaimStatus" :reduce="(value) => value.id" label="name" @keyup.enter="searchFilter" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Facilities</label>
            <v-select v-model="filters.facility_id" class="w-100" :options="facilitiesOptions"
              :reduce="(value) => value.id" label="name" @keyup.enter="searchFilter" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Form.Start Date") }}</label>
            <flat-pickr v-model="filters.visit_date" :config="config" class="form-control" placeholder="MM-DD-YYYY"
              type="date" />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Form.End Date") }}</label>
            <flat-pickr v-model="filters.date_end" :config="config" class="form-control" placeholder="MM-DD-YYYY"
              type="date" />
          </b-col>
          <!-- <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Types Of Visits</label>
            <v-select v-model="filters.type_visits_id" class="w-100" :options="typeVisitOptions"
              :reduce="(value) => value.id" label="name" @keyup.enter="searchFilter" />
          </b-col> -->
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Visit Status</label>
            <v-select v-model="filters.visit_statuses_id" class="w-100" :options="visitStatusOptions"
              :reduce="(value) => value.id" label="name" @keyup.enter="searchFilter" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button variant="primary" @click="searchFilter" :disabled="loading">
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCollapse,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import axiosFacility from "@/services/admin/facilities";
import SpinnerLoading from "@/components/SpinnerLoading";

import axiosLC from "@/services/admin/consults/visits";
import axiosS from "@/services/admin/consults/visitStatus";
import axiosCV from "@/services/admin/consults/claimStatus.js";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BCollapse,
    vSelect,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      claimStatusOptions: [],
      stateOptions: [],
      typeVisitOptions: [],
      facilitiesOptions: [],
      visitStatusOptions: [],
      roomOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      filters: {
        patient_name: null,
        visit_statuses_id: null,
        visit_date: null,
        claim_insurance_status_id: null,
        date_end: null,
        dob: null,
        facility_id: null,
      },
      statusOptions: [
        { name: "Active", value: "true" },
        { name: "Inactive", value: "false" },
      ],
      TypeUserOptions: [
        { name: "Established", value: "established" },
        { name: "New", value: "new" },
      ],
      config: fromConfig({ dateFormat: "m/d/Y", }),
      visible: false,
    };
  },
  mounted() {
    this.getCategory();
    this.getCategoryStatus();
    axiosCV.visitList(25).then(({ registro: { data } }) => {
      this.claimStatusOptions = data
    })
    axiosFacility.facilityList(20).then(({ data }) => {
      this.facilitiesOptions = data;
    });
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      filter.dob = filter.dob ? filter.dob.split('/').join('-') : null
      filter.visit_date = filter.visit_date ? filter.visit_date.split('/').join('-') : null
      filter.date_end = filter.date_end ? filter.date_end.split('/').join('-') : null
      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    filterClaimStatus(value) {
      if (!value) return
      axiosCV.visitFilter(25, { name: value }).then(({ registro: { data } }) => {
        this.claimStatusOptions = data;
      })
    },
    getCategory() {
      axiosLC.visitList(10).then(({ data }) => {
        this.typeVisitOptions = data;
      });
    },
    getCategoryStatus() {
      axiosS.visitStatusList(50).then(({ data }) => {
        this.visitStatusOptions = data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
  <b-modal v-model="conditionPay" @hide="onHide" hide-footer hide-header centered size="md">
    <validation-observer ref="payRef">
      <b-form class="auth-login-form mt-2" @submit.prevent="onSubmitPay">
        <b-form-group label="Payment" label-for="total">
          <b-form-input id="total" name="total" disabled :value="getTotal" />
        </b-form-group>
        <div class="d-flex justify-content-between align-items-center">
          <b-form-group label="Remaining" label-for="total">
            <b-form-input id="total" name="total" disabled :value="totalRemaining" />
          </b-form-group>
          <span class="mx-1">+</span>
          <b-form-group label="Convenience Fee" label-for="Convenience">
            <b-form-input id="Convenience" name="Convenience" disabled :value="convenienceFee" />
          </b-form-group>
          <span class="mx-1"> = </span>
          <b-form-group label="Total" label-for="total">
            <b-form-input id="total" name="total" disabled :value="totalPrice" />
          </b-form-group>
        </div>

        <!-- payment day -->
        <validation-provider #default="{ errors }" name="Payment Date" rules="required" ref="dateRules">
          <b-form-group label="Payment Date" label-for="payment-date">
            <flat-pickr v-model="paymentInfo.paymentDate" :config="config" class="form-control"
              placeholder="YYYY-MM-DD" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-button variant="success" @click="changeMethod"> Split Pay </b-button>

        <!-- payment method -->
        <div v-if="conditionSplit">
          <validation-provider #default="{ errors }" name="Payment Method" rules="required">
            <b-form-group label="Payment Method" label-for="payment-method" :state="errors.length > 0 ? false : null"
              class="my-1">
              <v-select id="payment-method" v-model="paymentInfo.paymentMethod" :options="paymentMethodOptions"
                :reduce="(value) => value.id" label="name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="Payment Amount" :rules="this.isPartialPay
            ? `required|min_value:0|max_value:${getTotal}`
            : `required|min_value:${totalRemaining}|max_value:${totalRemaining}`
            ">
            <b-form-group label="Payment Amount" label-for="payment-amount">
              <b-form-input id="payment-amount" name="payment-amount" v-model="amountCheck"
                :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="my-1" v-else>
          <b-row>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Credit Card" :rules="this.isPartialPay
                ? {
                  required: true,
                  totalRulesPartial: {
                    total: totalRemaining,
                    totalPay: splitPay,
                  },
                }
                : {
                  required: true,
                  totalRules: {
                    total: totalRemaining,
                    totalPay: splitPay,
                  },
                }
                " ref="rulesCredit">
                <b-form-group label="Credit Card" label-for="credit-card" :invalid-feedback="validateTotal">
                  <b-form-input id="credit-card" name="credit-card" v-model.number="creditCardAmount"
                    :state="errors.length > 0 ? false : null" @change="addConvenienceFee" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Cash" :rules="this.isPartialPay
                ? {
                  required: true,
                  totalRulesPartial: {
                    total: totalRemaining,
                    totalPay: splitPay,
                  },
                }
                : {
                  required: true,
                  totalRules: {
                    total: totalRemaining,
                    totalPay: splitPay,
                  },
                }
                " ref="rulesCredit">
                <b-form-group label="Cash" label-for="cash" :invalid-feedback="validateTotal">
                  <b-form-input id="cash" name="cash" v-model.number="cashAmount"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider></b-col>
          </b-row>
        </div>

        <!-- internal payment note-->
        <validation-provider #default="{ errors }" name="Internal Payment Note" v-if="!isMembership">
          <b-form-group label="Internal Payment Note" label-for="internal-payment-note"
            :state="errors.length > 0 ? false : null" class="my-1">
            <b-form-textarea id="internal-payment-note" v-model="itemAppointment.note"
              placeholder="Internal payment note" no-resize rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div class="mb-2" v-if="pendingMembership.status && !isMembership">
          <span class="text-warning d-block mb-2">{{ pendingMembership.message }}</span>
          <b-button variant="outline-danger" @click="confirmRemoveBenefit">Don't pay</b-button>
        </div>

        <b-form-group v-if="!isMembership" label="Partial Payment" label-for="Partial Payment">
          <b-form-checkbox v-model="isPartialPay"> </b-form-checkbox>
        </b-form-group>

        <div class="d-flex justify-content-end mt-2">
          <b-button type="button" class="mr-2" variant="outline-danger" @click="onHide">
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="primary" type="submit" :disabled="loading || itemAppointment.pay_status_id === 1">
            <span v-if="!loading">Pay</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosOV from "@/services/office-visit/index";
import axiosA from "@/services/appointment";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormCheckbox,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    itemAppointment: {
      type: Object,
      required: true,
    },
    isMembership: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  mixins: [NotificationMixin],
  watch: {
    isPartialPay(newValue) {
      if (newValue === true) {
        this.$swal({
          title: '<h3 class="font-weight-bolder">Selected Partial Payment</h3>',
          showCancelButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-outline-primary",
          },
          buttonsStyling: false,
        });
        this.methodAxios = "procedurePayPartial";
      } else {
        this.methodAxios = "procedurePay";
      }
    },
  },
  computed: {
    getTotal() {
      return this.isMembership && this.itemAppointment.active_membership ? this.itemAppointment.active_membership.price : this.itemAppointment.total
    },
    splitPay() {
      return parseFloat(this.cashAmount) + parseFloat(this.creditCardAmount);
    },
    totalPrice() {
      return parseFloat(this.convenienceFee) + parseFloat(this.totalRemaining);
    },
    validateTotal() {
      extend("totalRules", {
        params: ["total", "totalPay"],
        validate: (value, { total, totalPay }) => {
          if (totalPay == parseFloat(total)) {
            return true;
          } else {
            return false;
          }
        },
        message: (field, { total }) =>
          `El ${field} must be higher or greater to ${total}`,
      });
      extend("totalRulesPartial", {
        params: ["total", "totalPay"],
        validate: (value, { total, totalPay }) => {
          if (totalPay <= total) {
            return true;
          } else {
            return false;
          }
        },
        message: (field, { total }) =>
          `El ${field} must be under or greater to ${total}`,
      });
    },
    amountCheck: {
      get() {
        if (this.paymentInfo.paymentMethod == 1) {
          this.convenienceFee = 0
          return this.cashAmount;
        } else {
          this.addConvenienceFee();
          return this.creditCardAmount;
        }
      },
      set(val) {
        if (this.paymentInfo.paymentMethod == 1) {
          this.cashAmount = val;
          this.creditCardAmount = 0;
        } else {
          this.cashAmount = 0;
          this.creditCardAmount = val;
        }
      },
    },
    totalRemaining() {
      if (
        this.itemAppointment.cc !== null &&
        this.itemAppointment.cash !== null
      ) {
        const totalPayment = this.isMembership ? this.itemAppointment.active_membership.price :
          parseFloat(this.itemAppointment.cc) +
          parseFloat(this.itemAppointment.cash);
        return this.isMembership ? totalPayment : this.itemAppointment.total - totalPayment;
      }
      return this.isMembership ? this.getTotal : this.itemAppointment.total;
    },
  },
  data() {
    return {
      conditionPay: true,
      conditionSplit: true,
      isPartialPay: false,
      methodAxios: "procedurePay",
      paymentInfo: {
        paymentDate: new Date(),
        paymentMethod: null,
      },
      paymentMethodOptions: [
        {
          name: "Cash",
          id: 1,
        },
        {
          name: "Credit Card",
          id: 2,
        },
      ],
      convenienceFee: 0,
      cashAmount: 0,
      creditCardAmount: 0,
      config: {
        static: true,
      },
      loading: false,
      pendingMembership: {
        status: false,
        message: ''
      },
    };
  },
  mounted() {
    if (this.itemAppointment.cc != null && this.itemAppointment.cash != null) {
      this.conditionSplit = false;
    } else if (
      this.itemAppointment.cc != null &&
      this.itemAppointment.cash == null
    ) {
      this.paymentInfo.paymentMethod = 2;
    } else {
      this.paymentInfo.paymentMethod = 1;
    }
    if (this.itemAppointment.pay_status_id === 1) {
      this.cashAmount = this.itemAppointment.cash;
      this.creditCardAmount = this.itemAppointment.cc;
    }

    if (this.itemAppointment.membership_id !== null) {
      this.getPendingMembership();
    }

  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    changeMethod() {
      this.conditionSplit = !this.conditionSplit;
    },
    onSubmitPay() {
      this.$refs.payRef.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          const data = {
            cash: this.cashAmount,
            cc: this.creditCardAmount,
            note: this.itemAppointment.note,
            convenience_fee: this.convenienceFee,
          };
          if (this.isMembership) {
            const newData = {
              payment: data,
              user_id: this.itemAppointment.id,
              membership_id: this.itemAppointment.active_membership.id
            }
            await axiosA.payMembership(newData).then((res) => {
              const { type, message } =
                NotificationMixin.methods.notificationCheck(res);
              this.loading = false;
              this.$emit("NotificationCheck", { type, message });
              this.$emit("paymentChecked", true);
            })
              .catch((e) => {
                this.loading = false;
              });
            this.$emit("closeModalPay", false);
            return
          }
          await axiosOV[this.methodAxios](this.itemAppointment.id, data)
            .then((res) => {
              const { type, message } =
                NotificationMixin.methods.notificationCheck(res);
              this.loading = false;
              this.$emit("NotificationCheck", { type, message });
              this.$emit("paymentChecked", true);
            })
            .catch((e) => {
              this.loading = false;
            });
          this.$emit("closeModalPay", false);
        } else {
          console.log("No");
        }
      });
    },
    validationMethod() {
      return new Promise((resolve, reject) => {
        this.$refs.dateRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    addConvenienceFee() {
      const totalConvenience = (this.creditCardAmount * 3) / 100;
      this.convenienceFee = parseFloat(totalConvenience.toFixed(2));
    },
    getPendingMembership() {
      axiosOV.pendingPayMembership(this.itemAppointment.id).then((resp) => {
        this.pendingMembership = {
          status: true,
          message: resp.message
        }
      });
    },
    confirmRemoveBenefit() {
      this.$swal({
        title: '<span class="font-weight-bolder">ATENTION!</span>',
        html: '<span class="font-weight-bolder">You are sure you want to remove the benefit?</span>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeBenefitMembership(true);
        }
      });
    },
    removeBenefitMembership(confirm = false) {
      if (!confirm) return;

      axiosOV.removeMembership(this.itemAppointment.id, {}).then((resp) => {
        axiosA.appointmentDetail(this.itemAppointment.id).then((res) => {
          this.$emit('updateItemAppoiment', res.registro.appointment);
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
